;(function($){

    function Slides() {
   	 	var _ =  this;
   	 	//teste
		$('.slider').slick({
		  dots: false,
		  arrows: true,
		  infinite: true,
		  speed: 500,
		  fade: true,
		  cssEase: 'linear',
		  autoplay: true,
  		  autoplaySpeed: 3500,
		});

		$('.slider-dealer').slick({
	      vertical: false,
	      dots: false,
	      infinite: true,
	      speed: 300,
	      autoplay: true,
	      autoplaySpeed: 5500,
	      slidesToShow: 6,
	      slidesToScroll: 1,
	      arrows: true,
	      responsive: [
	        {
	          breakpoint: 1000,
	          settings: {
	            slidesToShow: 3,
	            slidesToScroll: 1,
	            dots: false
	          }
	        },
	        {
	          breakpoint: 600,
	          settings: {
	            slidesToShow: 3,
	            slidesToScroll: 1,
	            dots: false
	          }
	        },
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            dots: false,
	            arrows: false
	          }
	        }
	      ]
	    });		
    }
    
   	new Slides();

}(jQuery));
 
 
 
 
